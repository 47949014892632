import React, { useCallback } from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'

import { Text, RadioIcon } from 'components/dataDisplay'

import s from './RadioButton.scss'


export type RadioButtonProps = {
  children?: any
  className?: string
  field: Field<any>
  label?: string
  value: any
  disabled?: boolean
  fullWidth?: boolean
  ariaLabel?: string
  dataTestId?: string
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const {
    className, field, label, value,
    disabled, fullWidth,
    ariaLabel, dataTestId,
  } = props

  const state = useFieldState(field)

  const isChecked = state.value === value

  const rootClassName = cx(s.root, className, 'inline-flex items-center', {
    [s.disabled]: disabled,
    [s.fullWidth]: fullWidth,
  })

  const handleClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    field.set(value)
  }, [ field, value ])

  return (
    <button
      className={rootClassName}
      role="radio"
      disabled={disabled}
      aria-label={ariaLabel}
      aria-checked={value}
      data-testid={dataTestId}
      onClick={handleClick}
    >
      <RadioIcon active={isChecked} />
      {
        Boolean(label) && (
          <Text
            className={cx(s.label, 'ml-12px')}
            message={label}
            size="t16-20"
            color={disabled ? 'alien' : 'titanic'}
            html
          />
        )
      }
    </button>
  )
}


export default RadioButton
