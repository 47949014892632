import React from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'

import s from './TimeSlot.scss'


type TimeSlotProps = {
  className?: string
  message: string
  isSelected: boolean
  onClick: () => void
}

const TimeSlot: React.FC<TimeSlotProps> = (props) => {
  const { className, message, isSelected, onClick } = props

  return (
    <Href
      className={cx(className, s.day, 'flex items-center justify-center mt-12px mx-8px relative pointer', {
        [s.selected]: isSelected,
      })}
      onClick={onClick}
    >
      <Text
        size="s13-r"
        message={message}
        color="interstellar"
      />
    </Href>
  )
}

export default TimeSlot