import React, { useCallback, useMemo, useRef } from 'react'
import { Field } from 'formular'
import { FileDrop } from 'react-file-drop'

import { openSubmitFailedModal } from 'compositions/modals'

import { useUpload } from './util'


export type UploadableProps = {
  className?: string
  filesField: Field<string[]>
  title?: string
  message?: string
  maxFiles?: number
  acceptTypes?: ('image' | 'docs' | 'video' | 'sheets')[]
  orderId: number | 'direct' // backend constant; different for each layer
  isUploadForImport?: boolean // this is for the case when we uploading file template for universal import(see <ImportFromFileModal/>) // TODO rework for formDataProps object
  multipleFiles?: boolean
  onLoad?: (data: any) => void
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}

const mimeTypes = new Map([
  [ 'image', 'image/jpeg, image/png' ],
  [ 'docs', `application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/pdf` ],
  [ 'video', 'video/mp4' ],
  [ 'sheets', `application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  text/csv` ],
])

const Uploadable: React.FC<UploadableProps> = (props) => {
  const {
    children,
    className,
    filesField,
    maxFiles = 5,
    acceptTypes,
    orderId,
    isUploadForImport,
    multipleFiles,
    onLoad,
  } = props

  const { uploadFile, deleteFile, isDeleting, isUploading } = useUpload()

  const fileInputRef = useRef(null)

  const allowedMimes = useMemo(() => (
    acceptTypes?.reduce((mimes, type) => {
      const mimesString = mimeTypes.get(type)

      if (mimesString) {
        return mimes.concat(mimesString.split(', '))
      }

      return mimes
    }, [])
  ), [ acceptTypes ])

  const handleFileInputChange = useCallback(async (inputFiles: FileList, event) => {
    let allowedFiles = Array.from(inputFiles).filter((file) => {
      const isAllowed = allowedMimes?.includes(file.type) || true

      if (!isAllowed) {
        console.warn(`${file.type} not allowed. File filtered`, allowedMimes)
      }

      return isAllowed
    })

    if (allowedFiles.length > maxFiles) {
      console.warn(`Maximum ${maxFiles} allowed. Rest files have been filtered`, allowedFiles.length)

      allowedFiles = allowedFiles.slice(0, maxFiles)
    }

    const uploadFormData = new FormData()

    uploadFormData.append('orderId', String(orderId))
    allowedFiles.forEach((file) => uploadFormData.append('files[]', file))
    if (isUploadForImport) {
      uploadFormData.append('upload_url', 'import')
      uploadFormData.append('replaceFile', '1')
    }

    // uploadFormData.append('renameShort', '0')

    const { data } = await uploadFile({
      body: uploadFormData,
    })

    const { files: uploadedFiles } = data

    const fileErrors = uploadedFiles.filter((file) => file?.error)

    const filesWithoutError = uploadedFiles.filter((file) => {
      const isWithError = file?.error

      if (isWithError) {
        filesField.setError(`Ошибка загрузки: ${file.error}`)
      }

      return !isWithError
    })

    if (fileErrors.length) {
      const errors = fileErrors.map((file) => file.url).join('<br />')

      openSubmitFailedModal({
        title: 'Ошибка',
        subTitle: `${fileErrors.length} из ${uploadedFiles.length} файлов не были загружены`,
        text: `<span class="color-fargo">${errors}</span>`,
        textHtml: true,
        buttonTitle: 'Понятно',
      })
    }

    if (filesWithoutError.length === uploadedFiles.length && filesField.state.error) {
      filesField.setError(null)
    }

    filesWithoutError.forEach((file) => {
      if (file.url) {
        const decodedUrl = decodeURI(file.url)

        filesField.set(filesField.state.value ? filesField.state.value.concat(decodedUrl) : [ decodedUrl ])

        if (onLoad) {
          onLoad(file)
        }
      }
    })

  }, [ allowedMimes, maxFiles, orderId, isUploadForImport, uploadFile, filesField, onLoad ])

  const handleFileDelete = useCallback(async (index) => {
    const fileName = filesField.state.value.find((file, fileIndex) => fileIndex === index)
    const updatedFileName = fileName?.replace(/.+\//, '')

    const { data } = await deleteFile({
      url: `/uploadfile/index.php?file=${encodeURI(updatedFileName)}&_method=DELETE&orderId=${String(orderId)}`,
    })

    const newState = filesField.state.value.filter((file, fileIndex) => fileIndex !== index)

    filesField.set(newState)
  }, [ filesField, orderId, deleteFile ])

  const accept = acceptTypes ? allowedMimes.join(', ') : undefined

  return (
    <FileDrop
      className={className}
      onDrop={handleFileInputChange}
      onTargetClick={() => fileInputRef.current.click()}
    >
      {children}
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        accept={accept}
        multiple={multipleFiles}
        onChange={(event) => handleFileInputChange(event.target.files, event)}
      />
    </FileDrop>
  )
}


export default Uploadable
