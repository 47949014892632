import { string } from 'helpers/primitives'


export const createMask = (formula) => {
  const patternArray = formula.split('')
  return (value) => {
    if (!value) {
      return ''
    }
    let result = '', index = 0

    for (let i = 0; i < patternArray.length; i++) {
      if (value[index] === undefined) {
        break
      }

      const symbol = patternArray[i]

      result += symbol === 'X'
        ? value[index++]
        : symbol
    }
    return result
  }
}

type CreateMaskWithModifiers = (params: {
  mask: string
  preModify?: (value: string) => string
  postModify?: (value: string) => string
}) => (value: string) => string

const createMaskWithModifiers: CreateMaskWithModifiers = ({ mask, preModify, postModify }) => {
  const applyMask = mask && createMask(mask)

  return (value) => {
    let newValue = value

    if (typeof preModify === 'function') {
      newValue = preModify(newValue)
    }

    if (typeof applyMask === 'function') {
      newValue = applyMask(newValue)
    }

    if (typeof postModify === 'function') {
      newValue = postModify(newValue)
    }

    return newValue
  }
}

// Mask result - mm/dd/yyyy
const initDateMask = (): ReturnType<CreateMaskWithModifiers> => {
  const preModify = (value) => value.replace(/\D/g, '')

  const postModify = (value) => {
    let [ month, day, yaer ] = value.split('/')

    if (month && month > 12) {
      month = 12
    }

    if (day && day > 31) {
      day = 31
    }

    return [ month, day, yaer ].filter(Boolean).join('/')
  }

  return createMaskWithModifiers({
    mask: 'XX.XX.XXXX',
    preModify,
    postModify,
  })
}

// Mask result - 10:22 or 10:22:33
const initTimeMask = (): ReturnType<CreateMaskWithModifiers> => {
  const preModify = (value) => value.replace(/\D/g, '')

  const postModify = (value) => {
    console.log({ value })
    let [ hours, minutes, seconds ] = value.split(':')

    if (hours && Number(hours) > 23) {
      hours = 23
    }

    if (minutes && Number(minutes) > 59) {
      minutes = 59
    }

    if (seconds && Number(seconds) > 59) {
      seconds = 59
    }

    return [ hours, minutes, seconds ].filter(Boolean).join(':')
  }

  return createMaskWithModifiers({
    mask: 'XX:XX:XX',
    preModify,
    postModify,
  })
}

// Mask result - asd sda => Asd sda
const initCapitalizeMask = (): ReturnType<CreateMaskWithModifiers> => {
  return (value) => string.capitalize(value)
}

// Mask result - yyyy
const initYearMask = ({ min, max }: { min: number, max: number }): ReturnType<CreateMaskWithModifiers> => {
  const preModify = (value) => value.replace(/\D/g, '')

  const postModify = (value) => {
    if (value) {
      const firstChar = value[0] || '0'
      const secondChar = value[1] || '0'
      const thirdChar = value[2] || '0'
      const fourthChar = value[3] || '0'

      const year = [ firstChar, secondChar, thirdChar, fourthChar ].join('')
      const yearNumber = Number(year)

      let result = ''

      if (min > yearNumber) {
        result = String(min)
      }
      if (max < yearNumber) {
        result = String(max)
      }

      if (result) {
        value = result.slice(0, value.length)
      }
    }

    return value
  }

  return createMaskWithModifiers({
    mask: 'XXXX',
    preModify,
    postModify,
  })
}

// Mask result - +7 987 111-22-23
const initPhoneMask = (): ReturnType<CreateMaskWithModifiers> => {
  const preModify = (value) => value.replace('+7 ', '').replace(/\D/g, '')

  return createMaskWithModifiers({
    mask: '+7 XXX XXX-XX-XX',
    preModify,
  })
}

// Mask result - 100.22
const initPriceMask = (): ReturnType<CreateMaskWithModifiers> => {
  const preModify = (value) => value.replace(/\s|[a-zA-Z]/g, '')

  const postModify = (value) => {
    const dotRegex = /[.,]/

    const [ before, after ] = value.split(dotRegex)

    const result = [ before, after ]
      .filter(Boolean)
      .map((val, index) => {
        const result = val?.replace(/\D/g, '') || ''

        return index ? result.slice(0, 2) : result
      })
      .join('.')

    if (dotRegex.test(value) && !dotRegex.test(result)) {
      return `${result}.`
    }

    return result
  }

  return createMaskWithModifiers({
    mask: '',
    preModify,
    postModify,
  })
}

export default {
  phone: initPhoneMask(),
  date: initDateMask(),
  time: initTimeMask(),
  price: initPriceMask(),
  capitalize: initCapitalizeMask(),
  year: initYearMask({ min: 1800, max: new Date().getFullYear() }),
  year_1800_1990: initYearMask({ min: 1800, max: 1990 }),
  year_1900_now: initYearMask({ min: 1900, max: new Date().getFullYear() }),
}
