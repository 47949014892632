import React, { forwardRef } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { InlineSvg, Text } from 'components/dataDisplay'

import { ButtonBase } from 'components/inputs'
import TagView, { TagViewProps } from '../TagView/TagView'

import s from './TagRemovable.scss'

import closeIcon from './images/close.svg'


export type TagRemovableProps = {
  className?: string
  title?: string
  color: TagViewProps['color']
  onClick?: () => void
}

const TagRemovable = forwardRef<HTMLAnchorElement | HTMLButtonElement, TagRemovableProps>((props, ref) => {
  const { className, title, color, onClick, ...rest } = props

  const { isDesktop } = useDevice()

  return (
    <TagView
      ref={ref}
      className={cx(s.tag, className)}
      color={color}
      {...rest}
    >
      <Text size="c13" message={title} color="white" />
      <ButtonBase
        className={cx(s.close, s[`color-${color}`], 'pl-6px', {
          'h-full flex items-center justify-end radius-16 opacity-48': isDesktop,
        })}
        onClick={onClick}
      >
        <InlineSvg
          src={closeIcon}
          width={12}
          height={12}
        />
      </ButtonBase>
    </TagView>
  )
})


export default TagRemovable
