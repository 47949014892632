import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Field, useFieldState } from 'formular'
import { useUniqueId } from 'hooks'
import cx from 'classnames'
import { required } from 'helpers/validators'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHtmlAttributes } from 'helpers/getters'

import masks from '../Input/util/masks'
import { InputProps } from '../Input/Input'

import s from './Textarea.scss'


export const styles = [ 'light', 'dark', 'transparent' ] as const

export type InputStyle = typeof styles[number]

export type TextareaProps = Omit<GlobalHtmlAttributes<'HTMLInputElement'>, 'data-testid'> & {
  className?: string
  id?: string
  field: Field<string>
  pattern?: string
  style?: InputStyle
  label: string
  note?: string
  mask?: InputProps['mask']
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: any) => void
  dataTestId?: string
  rows?: number
  fullHeight?: boolean
}

const Textarea: React.FC<TextareaProps> = (props) => {
  const {
    className, id, field, style = 'light', fullHeight, rows,
    label, note, pattern, mask, disabled,
    onFocus, onBlur, onChange,
    dataTestId = 'textarea',
    ...otherProps
  } = props

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [ isFocused, setFocusedState ] = useState(Boolean(otherProps.autoFocus))

  const { value, error } = useFieldState<string | number>(field)

  const handleFocus = useCallback((event) => {
    setFocusedState(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  const handleBlur = useCallback(async (event) => {
    // @ts-ignore
    await field.validate()
    setFocusedState(false)
    if (typeof onBlur === 'function') {
      onBlur(event)
    }

  }, [ field, onBlur ])

  const handleChange = useCallback((event) => {
    let value = event.target.value

    const applyMask = masks[mask]

    if (typeof applyMask === 'function') {
      value = applyMask(value)
    }
    else if (pattern) {
      value = value.replace(new RegExp(`[^${pattern}]+`, 'g'), '')
    }

    field.set(value)

    if (field.state.value === value) {
      // Imagine we have input with mask: "XX/XX/XXX" for date. user can type incorrect
      // value in field: "11/A", in this case "A" will be removed from value and field.set
      // be called with value "11", previous value (before user type "A") was "11" - react
      // will not re-render the component, but native "uncontrolled" change in the input already
      // happened and user sees "11/A" in the input. To avoid this we need replace input's value manually.
      inputRef.current.value = value
    }

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, pattern, mask, onChange ])

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus()
    }
  }, [ isFocused ])

  const isFilled = value !== ''
  const isErrored = Boolean(error)
  const isRequired = field.validators.includes(required)

  const inputAreaClassName = cx(s.inputArea, 'flex radius-8', s[`style-${style}`], {
    [s.focused]: isFocused,
    [s.filled]: isFilled,
    [s.errored]: isErrored,
    [s.disabled]: disabled,
    // 'flex-1': fullHeight,
  })

  const textContentClassName = cx(s.textContent, 'inline-flex flex-col justify-start relative')

  const uniqueId = useUniqueId('textarea-')
  const controlId = id || uniqueId
  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  return (
    <div className={cx(className)}>
      <div
        className={inputAreaClassName}
        onClick={disabled ? null : handleFocus}
      >
        <div className={textContentClassName}>
          <label className={cx(s.label, 'absolute')} htmlFor={controlId}>
            {label}
          </label>
          <textarea
            {...htmlAttrs}
            id={controlId}
            className={cx(s.input, 'mt-16px')}
            ref={inputRef}
            //@ts-ignore
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid={dataTestId}
            aria-invalid={isErrored}
            aria-required={isRequired}
            disabled={disabled}
            rows={rows}
          />
        </div>
      </div>
      {
        Boolean(note && !isErrored) && (
          <div className={cx(s.note, 'absolute')}>
            {note}
          </div>
        )
      }
      {
        isErrored && (
          <div
            className={cx(s.error, 'absolute')}
            data-testid={`${dataTestId}Error`}
          >
            {error}
          </div>
        )
      }
    </div>
  )
}


export default Textarea
