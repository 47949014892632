import React, { useState, useCallback, useRef, useEffect, MouseEventHandler } from 'react'
import { Field, useFieldState } from 'formular'
import { useUniqueId } from 'hooks'
import cx from 'classnames'
import { debounce as handleDebounce } from 'helpers'
import { required } from 'helpers/validators'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHtmlAttributes } from 'helpers/getters'

import { Href } from 'components/navigation'
import { Tooltip, TooltipPosition } from 'components/feedback'
import { Icon, IconName } from 'components/dataDisplay'

import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker'
import TimePicker from '../TimePicker/TimePicker'

import masks from './util/masks'

import s from './Input.scss'


export const styles = [ 'light', 'dark' ] as const

export type InputStyle = typeof styles[number]

export type InputProps = Omit<GlobalHtmlAttributes<'HTMLInputElement'>, 'data-testid'> & {
  className?: string
  id?: string
  field: Field<string | number>
  mask?: 'time' | 'date' | 'ipRange' | 'year' | 'year_1800_1990' | 'year_1900_now' | 'capitalize' | 'phone' | 'price'
  pattern?: string
  style?: InputStyle
  label: string
  note?: string
  title?: string
  withCalendar?: boolean
  calendarWithPeriod?: boolean
  withTimePicker?: boolean
  isPickOnlyBeforeToday?: boolean
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: any) => void
  dataTestId?: string
  icon?: IconName
  iconPosition?: 'left' | 'right'
  pickerPosition?: 'top' | 'bottom'
  iconTooltip?: string
  tooltipPosition?: TooltipPosition
  format?: DatePickerProps['format']
  debounce?: number
  onIconClick?: () => void
  loading?: boolean
}

const Input: React.FC<InputProps> = (props) => {
  const {
    className, id, field, style = 'light', title,
    label, note, mask, pattern, disabled, icon, iconPosition = 'left', iconTooltip, tooltipPosition = 'top-right',
    onIconClick,
    withCalendar = false, calendarWithPeriod = false, pickerPosition = 'bottom',
    onFocus, onBlur, onChange, withTimePicker = false,
    dataTestId = 'input', isPickOnlyBeforeToday, format, loading, debounce,
    ...otherProps
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [ isFocused, setFocusedState ] = useState(Boolean(otherProps.autoFocus))

  useEffect(() => {
    if (!otherProps.autoFocus) {
      return () => {
        setFocusedState(true)
      }
    }
  }, [ otherProps.autoFocus ])

  const { value, error } = useFieldState<string | number>(field)
  const [ isBlurBlocked, setBlurBlocked ] = useState(false)

  const isBlurBlockedRef = useRef(isBlurBlocked)
  isBlurBlockedRef.current = isBlurBlocked

  useEffect(() => {
    if ((withCalendar || withTimePicker) && isFocused && !isBlurBlocked) {
      setBlurBlocked(true)
    }
  }, [ withCalendar, isFocused, isBlurBlocked, withTimePicker ])

  useEffect(() => {
    if (isBlurBlockedRef.current) {
      setBlurBlocked(false)
      setFocusedState(false)
    }
  }, [ value ])

  const handleFocus = useCallback((event) => {
    setFocusedState(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  const handleBlur = useCallback(async (event) => {
    if (isBlurBlocked) return
    // @ts-ignore
    await field.validate()
    setFocusedState(false)
    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ isBlurBlocked, field, onBlur ])

  const handleChange = useCallback((event) => {
    let value = event.target.value

    const applyMask = masks[mask]

    if (typeof applyMask === 'function') {
      value = applyMask(value)
    }
    else if (pattern) {
      value = value.replace(new RegExp(`[^${pattern}]+`, 'g'), '')
    }

    field.set(value)

    if (field.state.value === value) {
      // Imagine we have input with mask: "XX/XX/XXX" for date. user can type incorrect
      // value in field: "11/A", in this case "A" will be removed from value and field.set
      // be called with value "11", previous value (before user type "A") was "11" - react
      // will not re-render the component, but native "uncontrolled" change in the input already
      // happened and user sees "11/A" in the input. To avoid this we need replace input's value manually.
      inputRef.current.value = value
    }

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, mask, pattern, onChange ])

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus()
    }
  }, [ isFocused ])

  const isFilled = value !== ''
  const isErrored = Boolean(error)
  const isRequired = field.validators.includes(required)

  const inputAreaClassName = cx(s.inputArea,
    'flex radius-8',
    s[`style-${style}`], {
      [s.focused]: isFocused,
      [s.filled]: isFilled,
      [s.errored]: isErrored,
      [s.disabled]: disabled || loading,
    })

  const textContentClassName = cx(s.textContent,
    'inline-flex flex-col justify-center relative', {
      ['ml-8px']: Boolean(icon && iconPosition === 'left'),
      ['mr-8px']: Boolean(icon && iconPosition === 'right'),
    })

  const uniqueId = useUniqueId('input-')
  const controlId = id || uniqueId
  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  const handleIconClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    onIconClick()
    event.preventDefault()
    event.stopPropagation()
    // if (isFocused) {
    //   inputRef.current.focus()
    // }
  }, [ onIconClick ])

  const iconNode = (
    <Href
      className={cx(s.icon, 'flex my-12px')}
      onClick={typeof onIconClick === 'function' ? handleIconClick : null}
    >
      {
        iconTooltip ? (
          <Tooltip message={iconTooltip} position={tooltipPosition}>
            <Icon
              name={icon}
            />
          </Tooltip>
        ) : (
          <Icon
            name={icon}
          />
        )
      }
    </Href>
  )

  return (
    <div className={cx(className, withCalendar ? 'relative' : '', 'radius-8')}>
      <div
        className={inputAreaClassName}
        onClick={(disabled || loading) ? null : handleFocus}
      >
        {
          Boolean(icon && iconPosition === 'left') && (
            iconNode
          )
        }
        <div className={textContentClassName}>
          <label className={cx(s.label, 'absolute')} htmlFor={controlId}>
            {loading ? 'Загрузка...' : label}
          </label>
          <input
            {...htmlAttrs}
            id={controlId}
            className={cx(s.input, 'mt-16px')}
            ref={inputRef}
            //@ts-ignore
            value={loading ? '' : value}
            title={title}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={debounce ? handleDebounce(handleChange, debounce) : handleChange}
            data-testid={dataTestId}
            aria-invalid={isErrored}
            aria-required={isRequired}
            disabled={disabled || loading}
          />
        </div>
        {
          Boolean(icon && iconPosition === 'right') && (
            iconNode
          )
        }
      </div>
      {
        Boolean(note && !isErrored) && (
          <div
            className={cx(s.note, {
              'pl-24px': Boolean(icon && iconPosition === 'left'),
            })}
          >
            {note}
          </div>
        )
      }
      {
        isErrored && (
          <div className={cx(s.error, 'absolute', {
            [ 'pl-24px' ]: Boolean(icon && iconPosition === 'left'),
          }
          )} data-testid={`${dataTestId}Error`}>
            {error}
          </div>
        )
      }
      {
        withCalendar && isFocused && (
          <DatePicker
            className={cx(s.picker, 'mt-8px shadow-titanic-1', {
              [s.top]: pickerPosition === 'top',
            })}
            field={field}
            format={format || 'DD.MM.YYYY'}
            pickPeriod={calendarWithPeriod}
            isPickOnlyBeforeToday={isPickOnlyBeforeToday}
          />
        )
      }
      {
        withTimePicker && isFocused && (
          <TimePicker
            className={cx(s.picker, 'mt-8px shadow-titanic-1', {
              [s.top]: pickerPosition === 'top',
            })}
            field={field}
            withShadow
          />
        )
      }
    </div>
  )
}


export default Input
