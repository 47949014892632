import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import cx from 'classnames'
import { Field, useField, useFieldState } from 'formular'

import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase, Dropdown, DropdownOption, DropdownProps, TagRemovable } from 'components/inputs'

import s from './MultipleOptionsDropdown.scss'


export type MultipleOptionsDropdownProps = Omit<DropdownProps, 'field'> & {
  className?: string
  field: Field<DropdownOption[]>
  selectedOptionsView?: 'list' | 'tags'
}

const getDropdownOptions = (options, selectedOptions) => {
  if (selectedOptions && options) {
    const selectedOptionsValues = selectedOptions.map(({ value }) => value)

    return options.filter(({ value }) => !selectedOptionsValues.includes(value))
  }

  return options || []
}

const MultipleOptionsDropdown: React.FC<MultipleOptionsDropdownProps> = (props) => {
  const {
    children, className, options, label, field: multipleOptionsField, withSearch, selectedOptionsView = 'tags',
    disabled, onChange,
  } = props

  const { value } = useFieldState<DropdownOption[]>(multipleOptionsField)

  const dropdownOptions = useMemo(() => getDropdownOptions(options, value), [ options, value ])

  const field = useField({
    value: '',
  })

  const { value: dropdownValue } = useFieldState<string | number>(field)

  const handleRemoveOption = useCallback((id) => {
    const option = value.find((option) => option.value === id)
    if (typeof onChange === 'function') {
      onChange(option)
    }
    multipleOptionsField.set(
      value.filter((option) => option.value !== id)
    )
  }, [ value, onChange, multipleOptionsField ])

  const refs = useRef({})

  refs.current = {
    // value,
    dropdownOptions,
    multipleOptionsField,
  }

  useEffect(() => {
    const { dropdownOptions, multipleOptionsField } = refs.current

    const option = dropdownOptions.find((dropdownOption) => dropdownOption.value === dropdownValue)

    if (option) {
      multipleOptionsField.set(
        multipleOptionsField.state.value.concat(option)
      )

      field.set('')
    }
  }, [ dropdownValue, field ])

  return (
    <div className={className}>
      <Dropdown
        field={field}
        options={dropdownOptions}
        label={label}
        withoutValue
        disabled={!dropdownOptions.length || disabled}
        withSearch={withSearch}
        onChange={onChange}
      />
      {
        Boolean(value.length && selectedOptionsView === 'tags' && !children) && (
          <div className={cx(s.container, 'flex flex-wrap mt-4px')}>
            {
              value.map(({ value, title }) => (
                <TagRemovable
                  key={value}
                  className="mt-12px ml-12px"
                  title={title}
                  color="alien"
                  onClick={() => handleRemoveOption(value)}
                />
              ))
            }
          </div>
        )
      }
      {
        Boolean(value.length && children) && (
          children({
            values: value,
            onRemoveButtonClick: handleRemoveOption,
          })
        )
      }
      {
        Boolean(value.length && selectedOptionsView === 'list' && !children) && (
          <div className="mt-16px">
            {
              value.map(({ value, title, subTitle }) => (
                <div
                  key={value}
                  className="flex items-start"
                >
                  <div className="flex-1">
                    <Text
                      message={title}
                      size="t16-20"
                      color="titanic"
                    />
                    <Text
                      message={subTitle}
                      className="opacity-48"
                      size="s13-r"
                      color="titanic"
                    />
                  </div>
                  <ButtonBase
                    className="ml-12px opacity-72"
                    onClick={() => handleRemoveOption(value)}
                  >
                    <Icon
                      name="main/delete_16"
                      color="fargo"
                    />
                  </ButtonBase>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  )
}


export default React.memo(MultipleOptionsDropdown)
