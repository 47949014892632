import cx from 'classnames'
import { Field } from 'formular'
import React, { useCallback, useEffect, useState } from 'react'

import { Text } from 'components/dataDisplay'

import TimeSlot from './components/TimeSlot/TimeSlot'

import s from './TimePicker.scss'


export type TimePickerProps = {
  className?: string
  field: Field<string>
  withShadow?: boolean
  onPicked?: (value: string) => void
}

const hours = Array.from({ length: 24 }, (value, index) => index < 10 ? '0' + String(index) : String(index))
const minutes = Array.from({ length: 60 }, (value, index) => index < 10 ? '0' + String(index) : String(index))

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const {
    className,
    field,
    withShadow,
    onPicked,
  } = props

  const [ isHourPicked, setIsHourPicked ] = useState<boolean>(false) // true when hour is picked at least 1 time
  const [ isMinutePicked, setIsMinutePicked ] = useState<boolean>(false) // true when minute is picked at least 1 time
  const isPicked = isHourPicked && isMinutePicked

  const [ selectedHourIndex, setSelectedHourIndex ] = useState<number>(() => hours.findIndex((hour) => field.state.value.split(':')[0] === hour))
  const [ selectedMinuteIndex, setSelectedMinuteIndex ] = useState<number>(() => minutes.findIndex((minute) => field.state.value.split(':')[1] === minute))

  const handleTimeSlotClick = useCallback((index: number, entity: 'hour' | 'minute') => {
    if (entity === 'hour') {
      setSelectedHourIndex(index)
      setIsHourPicked(true)
    }
    if (entity === 'minute') {
      setSelectedMinuteIndex(index)
      setIsMinutePicked(true)
    }
  }, [  ])

  useEffect(() => {
    if ((typeof selectedHourIndex === 'number' && selectedHourIndex !== -1)
      && (typeof selectedMinuteIndex === 'number' && selectedMinuteIndex !== -1)) {

      const selectedHour = hours[selectedHourIndex]
      const selectedMinute = minutes[selectedMinuteIndex]
      const selectedString = `${selectedHour}:${selectedMinute}`
      field.set(selectedString)

      if (isPicked && typeof onPicked === 'function') {
        onPicked(selectedString)
      }
    }
  }, [ field, isPicked, onPicked, selectedHourIndex, selectedMinuteIndex ])

  return (
    <div
      className={cx(s.container, className, 'radius-16 p-20px', {
        'big-shadow-titanic-1': withShadow,
      })}
      onClick={(event) => event.stopPropagation()}
    >
      <Text
        className="flex justify-center items-center opacity-48"
        message="Время"
        size="c16"
      />
      <div className="flex mt-16px">
        <div className={cx(s.timeSlotSlider, 'flex-1 flex flex-col')}>
          {
            hours.map((hour, index) => (
              <TimeSlot
                key={hour}
                className={cx({ 'mt-4px': index })}
                message={hour}
                onClick={() => handleTimeSlotClick(index, 'hour')}
                isSelected={selectedHourIndex === index}
              />
            ))
          }
        </div>
        <div className={cx(s.timeSlotSlider, 'flex-1 flex flex-col ml-4px')}>
          {
            minutes.map((minute, index) => (
              <TimeSlot
                key={minute}
                className={cx({ 'mt-4px': index })}
                message={minute}
                onClick={() => handleTimeSlotClick(index, 'minute')}
                isSelected={selectedMinuteIndex === index}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}


export default TimePicker
