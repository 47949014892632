import React, { forwardRef, useCallback } from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'

import s from './ButtonBase.scss'


export type ButtonBaseProps = {
  // basic
  children?: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  onMouseOut?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>

  // links
  to?: string
  href?: string
  toTab?: string
  download?: string

  disabled?: boolean
  loading?: boolean
  tag?: string
  type?: string
  id?: string
  tabIndex?: number
  dataTestId?: string
  dataAttributes?: any

  // ADA
  ariaLive?: string
  ariaLabel?: string
  ariaLabelledBy?: string
  ariaDescribedBy?: string
}

const ButtonBase = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonBaseProps>((props, ref) => {
  const {
    // basic
    children, id, className, onMouseOver, onMouseOut,

    // modifiers
    disabled, loading,

    // links
    to, toTab, href, download, onClick,

    // misc
    tag = 'button', type = 'button', tabIndex, dataAttributes, dataTestId,

    // ADA
    ariaLive,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props

  const hasLink = to || toTab || href

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (disabled || loading) {
      event.preventDefault()
      return
    }

    if (typeof onClick === 'function') {
      event.stopPropagation()

      if (!hasLink) {
        event.preventDefault()
      }

      onClick(event)
    }
  }, [ hasLink, onClick, disabled, loading ])

  let node: string | React.ElementType = tag

  let nodeProps: any = {
    ref,
    className: cx(s.buttonBase, className, 'inline-flex items-center', {
      'pointer': !disabled && !loading,
      'justify-center': !className?.includes('justify-between'),
    }),
    id,
    disabled,
    tabIndex,
    download,
    onClick: handleClick,
    onMouseOver,
    onMouseOut,
    'data-testid': dataTestId,
    'aria-live': ariaLive,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    'aria-busy': loading,
    ...dataAttributes,
  }

  if (hasLink) {
    node = Href

    nodeProps = {
      ...nodeProps,
      to,
      href,
      toTab,
    }
  }
  else if (tag === 'button' || tag === 'input') {
    nodeProps.type = type
  }
  else {
    nodeProps.role = 'button'
  }

  return React.createElement(
    node,
    nodeProps,
    children
  )
})


export default ButtonBase
