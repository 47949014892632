import React, { useCallback } from 'react'
import cx from 'classnames'

import { Field, useField, useFieldState } from 'formular'

import { Input, InputProps, Knob } from 'components/inputs'
import { Attachment, IconName } from 'components/dataDisplay'


type InputWithAttachmentProps = { // TODO add validation and mask
  className?: string
  field: Field<string[] | []>
  label: string
  attachmentIcon: IconName
  maxItems?: number
  disabled?: boolean
  mask?: InputProps['mask']
  pattern?: string
  inputValidate?: ((value: string) => string | void)[]
}

const InputWithAttachment: React.FC<InputWithAttachmentProps> = (props) => {
  const { className, field, label, attachmentIcon, maxItems, disabled, mask, pattern,
    inputValidate } = props

  const inputField = useField({
    value: '',
    validate: inputValidate,
  })

  const fieldState = useFieldState(field)

  const handleAdd = useCallback((text) => {
    inputField.validate().then(() => {
      if (inputField.state.isValid) {
        field.set([ ...fieldState.value, text ])
        inputField.set('')
      }
    })
  }, [ inputField, field, fieldState.value ])

  const handleDelete = useCallback((index) => {
    field.set(fieldState.value.filter((item, id) => id !== index))
  }, [ field, fieldState.value ])

  return (
    <>
      <div className={cx(className, 'flex justify-between')}>
        <Input
          className="w-full"
          field={inputField}
          label={label}
          disabled={disabled}
          mask={mask}
          pattern={pattern}
        />
        <Knob
          className="ml-8px no-shrink"
          size={40}
          style="primary"
          icon="main/add_20"
          disabled={disabled || (fieldState.value.length >= maxItems)}
          onClick={() => handleAdd(inputField.state.value)}
        />
      </div>
      {
        fieldState.value.map((item, index) => (
          <Attachment
            key={index} // 'item' can be not unique here so we are using index,
            // theoretically this can cause bugs as the attatchments list is dynamic
            className="mt-16px pr-48px"
            icon={attachmentIcon}
            title={item}
            onDeleteClick={() => handleDelete(index)}
            disabled={disabled}
          />
        ))
      }
    </>
  )
}

export default InputWithAttachment