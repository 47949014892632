import { useMutation } from 'hooks'


const useUpload = () => {
  const [ uploadFile, { isSubmitting: isUploading } ] = useMutation<{ files }>({
    url: '/uploadfile/index.php',
    headers: { 'Content-Type': 'multipart/form-data' },
    // onUploadProgress: (progressEvent) => {
    //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //   console.log(percentCompleted)
    // },
  })

  const [ deleteFile, { isSubmitting: isDeleting } ] = useMutation({
    url: '',
  })

  return {
    uploadFile,
    deleteFile,
    isUploading,
    isDeleting,
  }
}


export default useUpload
