import React, { MouseEventHandler } from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import TagView, { defaultColors, TagViewProps } from '../TagView/TagView'

import s from './TagButton.scss'


export type TagButtonProps = {
  className?: string
  color: typeof defaultColors[number]
  title: string
  active?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
}

const inActiveColors: Record<TagButtonProps['color'], TagViewProps['color']> = {
  rocky: 'alien',
  matrix: 'drive',
  lebowski: 'snatch',
  fargo: 'indiana',
}

const TagButton: React.FC<TagButtonProps> = (props) => {
  const { className, title, active, color, ...rest } = props

  return (
    <TagView
      className={cx(s.tag, className, {
        [s.active]: active,
      })}
      color={active ? color : inActiveColors[color]}
      hover
      {...rest}
    >
      <div className={cx(s.dot, 'radius-100 mr-4px')} />
      <Text size="c13" message={title} color="white" />
    </TagView>
  )
}


export default TagButton
