import React, { forwardRef, useEffect } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Icon, IconColor, IconName } from 'components/dataDisplay'

import s from './Knob.scss'


export const styles = [ 'primary', 'secondary', 'white' ] as const
export const sizes = [ 72, 64, 40, 32, 24 ] as const

export type KnobProps = ButtonBaseProps & {
  className?: string
  icon: IconName // TODO make only 20 and 16 available
  color?: IconColor
  style: typeof styles[number]
  size?: typeof sizes[number]
  circle?: boolean
  disabled?: boolean
}

const Knob = forwardRef<HTMLAnchorElement | HTMLButtonElement, KnobProps>((props, ref) => {
  const { className, style, icon, size = 40, circle, disabled, color, ...rest } = props

  // useEffect(() => {
  //   const isIconAvailable = {
  //     72: /32$/.test(icon),
  //     64: /32$/.test(icon),
  //     40: /20$/.test(icon),
  //     32: /16$/.test(icon),
  //     24: /16$/.test(icon),
  //   }[size]
  //
  //   if (!isIconAvailable) {
  //     throw new Error(`Knob error: Icon ${icon} not compatible with size ${size}`)
  //   }
  // }, [ icon, size ])

  return (
    <ButtonBase
      ref={ref}
      className={cx(s.knob, s[style], className, {
        [s[`size-${size}`]]: size,
        'radius-16': size === 72 && !circle, // not in design, should be tested
        'radius-8': size === 40 && !circle,
        'radius-6': size === 32 && !circle,
        'radius-4': size === 24 && !circle,
        'radius-100': circle,
        [s.disabled]: disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      <Icon name={icon} color={color || (style === 'primary' ? 'white' : 'rocky')} />
    </ButtonBase>
  )
})


export default Knob
