import React, { forwardRef } from 'react'
import { useDevice } from 'device'

import type { IconName } from 'components/dataDisplay'

import ButtonStyle, { ButtonStyleProps } from './ButtonStyle/ButtonStyle'
import ButtonContent, { ButtonContentProps } from './ButtonContent/ButtonContent'


export type ButtonProps = ButtonStyleProps & Omit<ButtonContentProps, 'color' | 'titleSize'> & {
  icon?: IconName
  customIcon?: string
  fullWidthOnMobile?: boolean
}

const colors = {
  primary: 'white',
  secondary: 'godfather',
  accent: 'white',
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>((props, ref) => {
  const { isMobile } = useDevice()

  const {
    icon, customIcon, size = 40,
    title, subTitle, loading, loadingMessage,
    fullWidth, fullWidthOnMobile,
    ...rest
  } = props

  const buttonSize = subTitle
    ? 48
    : icon ? 40 : size

  const titleSize = buttonSize === 24 ? 'c13' : 'c16'
  const buttonFullWidth = isMobile && fullWidthOnMobile || fullWidth

  return (
    <ButtonStyle ref={ref} loading={loading} size={buttonSize} fullWidth={buttonFullWidth} {...rest}>
      <ButtonContent
        title={title}
        subTitle={subTitle}
        titleSize={titleSize}
        icon={icon}
        customIcon={customIcon}
        color={colors[rest.style] as 'white' | 'godfather'}
        loading={loading}
        loadingMessage={loadingMessage}
      />
    </ButtonStyle>
  )
})


export default Button
