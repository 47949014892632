import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Text } from 'components/dataDisplay'

import s from './Link.scss'


export const colors = [ 'alien', 'indiana', 'drive', 'chinatown' ] as const
export const textColors = [ 'white', 'titanic', 'mulholland' ] as const
export const sizes = [ 'c16', 'c13', 't20-24' ] as const

export type LinkProps = ButtonBaseProps & {
  className?: string
  title: string
  color: typeof colors[number]
  textColor?: typeof textColors[number]
  size?: typeof sizes[number]
}

const Link = forwardRef<HTMLAnchorElement | HTMLButtonElement, LinkProps>((props, ref) => {
  const { className, title, color, size = 'c16', textColor = 'titanic', ...rest } = props

  return (
    <ButtonBase
      ref={ref}
      tag="a"
      className={cx(s.link, className, s[`color-${color}`])}
      {...rest}
    >
      <Text className={s.text} message={title} size={size} color={textColor} />
    </ButtonBase>
  )
})


export default Link
