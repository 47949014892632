import cx from 'classnames'
import { Field, useFieldState } from 'formular'
import { Listbox } from '@headlessui/react'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { Text, Icon, CheckboxIcon } from 'components/dataDisplay'

import s from './MultiSelectDropdown.scss'


export type MultiSelectDropdownOption = {
  value: number
  title: string
}

type MultiSelectDropdownProps = {
  className?: string
  label: string
  options: MultiSelectDropdownOption[]
  field: Field<number[]>
  disabled?: boolean
}

const getMessageFromSelectedItems = (selectedItems: number[], options: MultiSelectDropdownOption[]) => {
  return options.filter((option) => selectedItems.includes(option.value)).map((option) => option.title).join(', ')
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = (props) => {
  const { className, options, label, field: selectedItemsField, disabled } = props

  const { value: selectedItems } = useFieldState<number[]>(selectedItemsField)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ message, setMessage ] = useState<string>('')

  useEffect(() => {
    if (selectedItems) {
      setMessage(getMessageFromSelectedItems(selectedItems, options))
    }
  }, [ selectedItems, options ])

  const isSelected = (value: number) => typeof selectedItems.find((item) => item === value) === 'number'

  const handleToggleSelect = (value) => {
    let selectedItemsUpdated
    if (!isSelected(value)) {
      selectedItemsUpdated = [ ...selectedItems, value ]
    } else {
      selectedItemsUpdated = selectedItems.filter((item) => item !== value)
    }
    selectedItemsField.set(selectedItemsUpdated)
    setMessage(getMessageFromSelectedItems(selectedItemsUpdated, options))
    setIsOpen(true)
  }

  const optionsRef = useRef<HTMLDivElement>()

  const handleClick = useCallback((event) => {
    if (optionsRef.current?.contains(event.target)) {
      return
    }
    setIsOpen(false)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ handleClick ])

  const ListboxButton = () => (
    <div className="flex-1" onClick={!disabled ? () => setIsOpen(!isOpen) : () => {}}>
      <div
        className={cx(s.textArea,
          'flex radius-8 items-center', {
            [s.focused]: isOpen,
            [s.filled]: Boolean(selectedItems.length),
            [s.disabled]: disabled,
          })}
      >
        <div
          className={cx(s.textContent, 'inline-flex flex-col flex-1 justify-center relative overflow-hidden')}
        >
          <label className={cx(s.label, 'absolute')}>
            {label}
          </label>
          <Text
            className={cx(s.text, 'mt-16px whitespace-nowrap overflow-ellipsis')}
            color="titanic"
            size="c16"
            message={message}
            title={message}
          />
        </div>
        <Icon
          className={cx(s.arrow, 'my-12px ml-4px')}
          name={isOpen ? 'arrow/arrow-up_16' : 'arrow/arrow-down_16'}
          preload={isOpen ? 'arrow/arrow-down_16' : 'arrow/arrow-up_16'}
        />
      </div>
    </div>
  )

  return (
    <Listbox
      className={cx(className, 'min-w-1')}
      as="div"
      value={selectedItems}
      onChange={() => {}}
    >
      <Listbox.Button
        as={() => <ListboxButton />}
      />
      {
        isOpen && (
          <Listbox.Options
            static
            className="relative block"
            as="div"
            ref={optionsRef}
          >
            <div
              className={cx(s.options, 'absolute radius-8')}
            >
              {options.map(({ value, title }, index) => {
                return (
                  <Listbox.Option key={value} value={value} as="div">
                    {({ active }) => (
                      <button
                        className={cx(s.checkBox, s.option, 'inline-flex w-full items-center px-20px py-8px')}
                        role="checkbox"
                        disabled={disabled}
                        onClick={() => handleToggleSelect(value)}
                      >
                        <CheckboxIcon active={isSelected(value)} disabled={disabled} />
                        <Text
                          className={cx('ml-12px', { 'opacity-16': disabled })}
                          message={title}
                          size="c16"
                          color="titanic"
                          html
                        />
                      </button>
                    )}
                  </Listbox.Option>
                )
              })}
            </div>
          </Listbox.Options>
        )
      }
    </Listbox>
  )
}


export default MultiSelectDropdown