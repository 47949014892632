import React, { ReactNode } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { InlineSvg } from 'components/dataDisplay'

import { ButtonBase } from 'components/inputs'

import s from './Removable.scss'

import closeIcon from './images/close.svg'


export type RemovableProps = {
  children: ReactNode
  className?: string
  onClick?: () => void
}

const Removable: React.FC<RemovableProps> = (props) => {
  const { children, className, onClick } = props

  const { isDesktop } = useDevice()

  return (
    <span className={cx(s.tag, className, 'relative')}>
      {children}
      <ButtonBase
        className={cx(s.close, 'color-fargo', isDesktop ? 'h-full flex items-center justify-end radius-16 pr-12px' : 'pl-6px', {
          [s.absolute]: isDesktop,
        })}
        onClick={onClick}
      >
        <InlineSvg
          src={closeIcon}
          width={11}
          height={11}
        />
      </ButtonBase>
    </span>
  )
}


export default Removable
