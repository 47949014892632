import cx from 'classnames'
import { useField } from 'formular'
import ReactQuill from 'react-quill'
import React, { useCallback } from 'react'

import { Icon } from 'components/dataDisplay'
import { Dropdown, DropdownOption } from 'components/inputs'

import s from './CustomToolbar.scss'


type CustomToolbarProps = {
  quillRef: React.MutableRefObject<ReactQuill>
}

const CustomToolbar: React.FC<CustomToolbarProps> = (props) => {
  const { quillRef } = props

  const textField = useField({ value: 1 })

  const textOptions = [
    { value: 1, title: 'Текст' },
    { value: 2, title: 'Заголовок' },
  ]

  const handleTextSizeChange = useCallback((option: DropdownOption) => {
    quillRef.current.focus()
    const selection = quillRef?.current.getEditor().getSelection()
    const { index, length } = selection

    quillRef.current.getEditor().formatLine(index, length, 'header', option.value === 2 ? '2' : false)
  }, [ quillRef ])

  return (
    <div className="flex items-center" id="toolbar">
      <Dropdown
        className={cx(s.dropdown, 'ql-header')}
        field={textField}
        options={textOptions}
        label="Текст"
        onChange={handleTextSizeChange}
      />
      <div className={cx(s.withHr, 'pl-12px ml-12px')}>
        <button className="ql-bold">
          <Icon
            name="text/bold_16"
            color="interstellar"
          />
        </button>
        <button className="ql-underline ml-12px">
          <Icon
            name="text/underline_16"
            color="interstellar"
          />
        </button>
        <button className="ql-strike ml-12px">
          <Icon
            name="text/cross_16"
            color="interstellar"
          />
        </button>
      </div>
      <div className={cx(s.withHr, 'pl-12px ml-12px')}>
        <button className="ql-list" value="bullet">
          <Icon
            name="text/bullet-list_16"
            color="interstellar"
          />
        </button>
        <button className="ql-list ml-12px" value="ordered">
          <Icon
            name="text/number-list_16"
            color="interstellar"
          />
        </button>
      </div>
      <div className={cx(s.withHr, 'pl-12px ml-12px')}>
        <button className="ql-link">
          <Icon
            name="action/link_16"
            color="interstellar"
          />
        </button>
      </div>
    </div>
  )
}

export default React.memo(CustomToolbar)
