import React, { forwardRef } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Icon, Text } from 'components/dataDisplay'
import { DropdownMenu, DropdownMenuProps } from 'components/navigation'

import { ButtonBase } from 'components/inputs'
import TagView, { TagViewProps } from '../TagView/TagView'

import s from './TagExpandable.scss'


export type TagExpandableProps = {
  className?: string
  title?: string
  color: TagViewProps['color']
  tagItems: DropdownMenuProps['items']
  onClick?: () => void
}

const TagExpandable = forwardRef<HTMLAnchorElement | HTMLButtonElement, TagExpandableProps>((props, ref) => {
  const { className, title, color, tagItems, onClick, ...rest } = props

  const { isDesktop } = useDevice()

  const TagComponent = ({ isOpen, withButton }) => (
    <TagView
      ref={ref}
      className={cx(s.tag, className, 'cursor-default')}
      color={color}
      {...rest}
    >
      <Text size="c13" message={title} color="white" />
      {
        withButton && (
          <ButtonBase
            className={cx(s.close, s[`color-${color}`], isDesktop ? 'h-full flex items-center justify-end radius-16 pr-12px' : 'pl-6px', {
              [s.absolute]: isDesktop,
            })}
          >
            <Icon
              name={isOpen ? 'arrow/arrow-up_12' : 'arrow/arrow-down_12'}
              color="white"
            />
          </ButtonBase>
        )
      }
    </TagView>
  )

  if (tagItems) {
    return (
      <DropdownMenu
        items={tagItems}
        style="leftBorder"
        placement="bottomLeft"
      >
        <TagComponent withButton />
      </DropdownMenu>
    )
  }

  return (
    <TagComponent />
  )
})


export default TagExpandable
