import cx from 'classnames'
import ReactQuill from 'react-quill'
import sanitizeHtml from 'sanitize-html'
import { Field, Form, useFieldState } from 'formular'
import React, { useCallback, useRef } from 'react'

import CustomToolbar from './components/CustomToolbar'

import 'react-quill/dist/quill.snow.css'
import s from './TextEditor.scss'


type TextEditorProps = {
  className?: string
  fullHeight?: boolean
  field: Field<string>
}

const modules = {
  toolbar: {
    container: '#toolbar',
  },
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image',
]

const TextEditor: React.ForwardRefExoticComponent<TextEditorProps> = React.forwardRef((props) => {
  const { className, fullHeight = false, field } = props

  const quillRef = useRef<ReactQuill>()

  const htmlState = useFieldState<string>(field)

  const handleTextChange = useCallback((value) => {
    field.set(sanitizeHtml(value))
  }, [ field ])


  return (
    <div className={cx(className, s.textEditor)}>
      <CustomToolbar quillRef={quillRef}/>
      <ReactQuill
        className={cx('mt-16px', { [ s.fullHeight ]: fullHeight })}
        ref={quillRef}
        modules={modules}
        formats={formats}
        placeholder="Текст"
        theme={null}
        defaultValue={htmlState.value}
        onChange={handleTextChange}
      />
    </div>
  )
})

export default TextEditor