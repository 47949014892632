import React, { forwardRef } from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'

import s from './HeaderButton.scss'


export const sizes = [ 'h56', 'h40', 'h32', 'h28', 'h24', 'h20-24' ] as const

export type HeaderButtonProps = {
  className?: string
  withArrow?: boolean
  title: string
  size?: typeof sizes[number]
  disabled?: boolean
  to?: string
  toTab?: string
}

const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  const { className, title, size = 'h20-24', disabled, withArrow = true, to, toTab } = props

  return (
    <Href
      className={cx(s.headerButton, className, 'flex items-center', {
        // [s[`size-${size}`]]: !title,
        [s.disabled]: disabled,
      })}
      to={to}
      toTab={toTab}
    >
      {
        title && (
          <Text
            className={cx(s.headerButton, 'overflow-ellipsis webkit-box')}
            size={size}
            message={title}
            color="titanic"
          />
        )
      }
      {
        withArrow && (
          <div className={cx({
            // 'pb-4px': size === 'h24',
          })}>
            <Icon
              className={cx('ml-8px')}
              name={
                size === 'h56' ? 'arrow/arrow-bold-right_32'
                  : size === 'h40' ? 'arrow/arrow-bold-right_24'
                    : size === 'h32' ? 'arrow/arrow-bold-right_20'
                      : size === 'h28' ? 'arrow/arrow-bold-right_16'
                        : size === 'h24' ? 'arrow/arrow-bold-right_16'
                          : 'arrow/arrow-bold-right_12'
              }
              color="inherit"
            />
          </div>
        )
      }
    </Href>
  )
}


export default HeaderButton
