import React, { useMemo, useState } from 'react'
import { Field, useFieldState } from 'formular'

import { Icon, Text } from 'components/dataDisplay'

import { ButtonBase } from '..'


const marks = [ 1, 2, 3, 4, 5 ] as const

export type Mark = typeof marks[number]

export type TextOption = {
  value: Mark
  title: string
}

type RatingProps = {
  className?: string
  field: Field<Mark>
  label?: string
  textOptions?: TextOption[]
  iconSize?: 20 | 32
}

const Rating: React.FC<RatingProps> = (props) => {
  const { className, field, label, textOptions, iconSize = 20 } = props

  const [ starCount, setStarCount ] = useState<number>()
  const { value: currentMark, error } = useFieldState(field)

  const currentText = useMemo(() => textOptions?.find(({ value }) => value === starCount)?.title, [ starCount, textOptions ])
  // TODO handle validate errored field on blur
  return (
    <div className={className}>
      {
        label && (
          <Text
            message={label}
            size="h16-24"
            color="titanic"
          />
        )
      }
      <div className="flex mt-8px">
        <>
          {
            Array.from({ length: 5 }).map((_, index) => (
              <ButtonBase
                key={index}
                onClick={() => field.set(index + 1)}
                onMouseOver={() => setStarCount(index + 1)}
                onMouseOut={() => setStarCount(currentMark || 0)}
              >
                <Icon
                  className={index ? 'ml-8px' : ''}
                  name={index < starCount ? `rating/star-active_${iconSize}` : `rating/star_${iconSize}`}
                  color="rocky"
                />
              </ButtonBase>
            ))
          }
          {
            textOptions && (
              <Text
                className="ml-16px opacity-48 overflow-ellipsis"
                message={currentText}
                size="t16-20"
                color="titanic"
              />
            )
          }
        </>
      </div>
      {
        error && (
          <Text
            className="mt-4px"
            message={error}
            size="s13-r"
            color="fargo"
          />
        )
      }
    </div>
  )
}

export default Rating