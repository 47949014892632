import React, { useEffect, useState } from 'react'
import { Form } from 'formular'


type FieldPlainValue = string | number | boolean
type FieldValue = FieldPlainValue | FieldPlainValue[]

type FieldsStateProps = {
  children: (state: { values: Record<string, FieldValue>, errors: Record<string, string>, isFormChanged: boolean }) => any
  form?: Form<any>
  isFetching?: boolean
}

const FormState: React.FC<FieldsStateProps> = (props) => {
  const { children, form, isFetching } = props
  const [ isFormChanged, setFormChanged ] = useState(false)

  useEffect(() => {
    if (!isFetching) {
      const handleChange = () => {
        setFormChanged(true)
        form.off('change', handleChange)
      }

      const handleStateChange = () => {
        if (!form.state.isChanged) {
          setFormChanged(form.state.isChanged)
          form.on('change', handleChange)
        }
      }

      form.on('change', handleChange)
      form.on('state change', handleStateChange)

      return () => {
        form.off('change', handleChange)
        form.off('state change', handleStateChange)
      }
    }
  }, [ form, isFetching ])

  const [ state, setState ] = useState({
    values: form.getValues(),
    errors: form.getErrors(),
  })

  useEffect(() => {
    const fields = Object.keys(form.fields)

    const handleChange = () => {
      const state = {
        values: form.getValues(),
        errors: form.getErrors(),
      }

      setState(state)
    }

    fields.forEach((fieldName) => {
      form.fields[fieldName].on('change', handleChange)
    })

    return () => {
      fields.forEach((fieldName) => {
        form.fields[fieldName].off('change', handleChange)
      })
    }
  }, [ form, isFetching ])

  return children({ ...state, isFormChanged })
}


export default FormState
