import React from 'react'
import { Switch as SwitchWrapper } from '@headlessui/react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHtmlAttributes } from 'helpers/getters'

import { Text } from 'components/dataDisplay'

import s from './Switch.scss'


export const sizes = [ 20 ] as const

export type Size = typeof sizes[number]

export type SwitchProps = {
  className?: string
  label?: string
  field: Field<boolean>
  size?: Size
  onChange?: (value: boolean) => void
  disabled?: boolean
}

type SubComponents = {
  Group?: typeof SwitchWrapper['Group']
  Label?: typeof SwitchWrapper['Label']
}

export type SwitchComponent = React.FC<SwitchProps & GlobalHtmlAttributes> & SubComponents

const Component: SwitchComponent = (props) => {
  const { className, label, field, size = 20, disabled, onChange, ...rest } = props

  const { value } = useFieldState<boolean>(field)
  const globalHtmlAttrs = getGlobalHtmlAttrs(rest)

  const handleChange = (value) => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const rootClassName = cx('relative', s.switch, s[`size-${size}`], {
    [s.active]: value,
    'opacity-32': !value,
    'opacity-48': value && disabled,
  })

  return (
    <div className={cx(className, 'flex')}>
      <SwitchWrapper
        className={rootClassName}
        checked={value}
        onChange={disabled ? null : handleChange}
        {...globalHtmlAttrs}
      >
        <div className={s.handle} />
      </SwitchWrapper>
      {
        Boolean(label) && (
          <Text
            className={cx(
              'ml-6px',
              value ? 'opacity-72' : 'opacity-16'
            )}
            message={label}
            size="c16"
            color="titanic"
            html
          />
        )
      }
    </div>
  )
}

const Switch: SwitchComponent = Component

Switch.Group = SwitchWrapper.Group


export default Switch
