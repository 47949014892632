import React from 'react'
import cx from 'classnames'

import { Icon, IconName, Text } from 'components/dataDisplay'

import s from './ButtonContent.scss'


export type ButtonContentProps = {
  title: string
  icon?: IconName
  customIcon?: string
  color: 'white' | 'godfather'
  subTitle?: string
  titleSize: 'c13' | 'c16'
  loading?: boolean
  loadingMessage?: string
  contentCenter?: boolean
  html?: boolean
}

const ButtonContent: React.FC<ButtonContentProps> = (props) => {
  const { title, subTitle, titleSize, icon, customIcon, loading, loadingMessage = 'Загрузка...', color, html } = props

  if (!title) {
    return null
  }

  const titleNode = (
    <Text
      message={loading ? loadingMessage : title}
      size={titleSize}
      color={color}
      html={html}
    />
  )

  const titleRow = (icon || customIcon) ? (
    <div className="flex items-center">
      {
        customIcon ? (
          <img
            className={s.icon}
            src={customIcon}
          />
        ) : (
          <Icon
            className={s.icon}
            name={icon}
            color={color}
          />
        )
      }
      {titleNode}
    </div>
  ) : titleNode

  if (!subTitle) {
    return titleRow
  }

  return (
    <>
      {titleRow}
      <Text
        className={cx('opacity-64', {
          [s.subTitleOffset]: Boolean(icon),
        })}
        message={subTitle}
        size="c13"
        color={color}
        html={html}
      />
    </>
  )
}


export default ButtonContent
