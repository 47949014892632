import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'

import s from './TagView.scss'


export const defaultColors = [ 'rocky', 'matrix', 'lebowski', 'fargo' ] as const
export const activeColors = [ 'alien', 'drive', 'snatch', 'indiana' ] as const
export const colors = [ ...defaultColors, ...activeColors ] as const

export type TagViewProps = ButtonBaseProps & {
  className?: string
  color: typeof colors[number]
  hover?: boolean
}

const TagView = forwardRef<HTMLAnchorElement | HTMLButtonElement, TagViewProps>((props, ref) => {
  const { children, className, color, hover, ...rest } = props

  return (
    <ButtonBase
      ref={ref}
      className={cx(s.tag, s[`color-${color}`], className, 'px-12px py-4px radius-16 relative', {
        [s.hover]: hover,
      })}
      {...rest}
    >
      {children}
    </ButtonBase>
  )
})


export default TagView
