import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Icon, IconName, Text } from 'components/dataDisplay'

import s from './ArrowButton.scss'


export const colors = [ 'titanic', 'rocky', 'lebowski' ] as const
export const arrows = [ 'up', 'down', 'left', 'right' ] as const
export const positions = [ 'left', 'right' ] as const

export type ArrowButtonProps = ButtonBaseProps & {
  className?: string
  textClassName?: string
  title: string
  color: typeof colors[number]
  arrow: typeof arrows[number]
  position: typeof positions[number]
  disabled?: boolean
}

const icons: Record<ArrowButtonProps['arrow'], IconName> = {
  'up': 'arrow/arrow-up_12',
  'down': 'arrow/arrow-down_12',
  'left': 'arrow/arrow-left_12',
  'right': 'arrow/arrow-right_12',
}

const ArrowButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, ArrowButtonProps>((props, ref) => {
  const { className, textClassName, title, color, arrow, position, disabled, ...rest } = props

  const icon = icons[arrow]

  return (
    <ButtonBase
      ref={ref}
      className={cx(s.arrowButton, s[`color-${color}`], className, {
        [s.disabled]: disabled,
      })}
      {...rest}
    >
      {
        position === 'left' && (
          <Icon
            className="mr-8px"
            name={icon}
            color="inherit"
          />
        )
      }
      <Text className={textClassName} size="c16" message={title} color="inherit" />
      {
        position === 'right' && (
          <Icon
            className="ml-8px"
            name={icon}
            color="inherit"
          />
        )
      }
    </ButtonBase>
  )
})


export default ArrowButton
