import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Text } from 'components/dataDisplay'

import s from './LinkButton.scss'


export type LinkButtonProps = ButtonBaseProps & {
  className?: string
  title: string
  disabled?: boolean
}

const LinkButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, LinkButtonProps>((props, ref) => {
  const { className, title, disabled, ...rest } = props

  return (
    <ButtonBase
      ref={ref}
      className={cx(s.linkButton, className, 'px-16px radius-8', {
        [s.disabled]: disabled,
      })}
      {...rest}
    >
      <Text className={s.text} size="c16" message={title} color="inherit" />
    </ButtonBase>
  )
})


export default LinkButton
